<template>
  <Toast />

  <div class="card">
    <DataTable
      ref="dt"
      :value="users"
      dataKey="id"
      :paginator="true"
      :rows="10"
      class="dt pop14"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
    >
      <template #header>
        <div class="table-header pop20">
          <div class="p-d-flex p-jc-between">
            <div>
              Manage users
              <div></div>
              <Button
                label="New User"
                icon="pi pi-plus"
                class="p-button-success p-mr-2"
                @click="openNew"
              />
            </div>
          </div>
        </div>
      </template>

      <Column field="id" header="Id" :sortable="true"></Column>
      <Column field="firstName" header="Firstname" :sortable="true"></Column>
      <Column field="lastName" header="Lastname" :sortable="true"></Column>
      <Column field="username" header="Username" :sortable="true"></Column>
      <Column field="createdAt" header="Created" :sortable="true"></Column>
      <Column>
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editUser(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDeleteUser(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    v-model:visible="userDialog"
    :style="{ width: '450px' }"
    header="User Details"
    :modal="true"
    class="p-fluid pop14"
  >
    <div class="p-field">
      <label for="firstname">Firstname</label>
      <InputText
        id="firstname"
        v-model.trim="user.firstName"
        required="true"
        autofocus
        :class="{ 'p-invalid': submitted && !user.firstName }"
      />
      <small class="p-error" v-if="submitted && !user.firstName"
        >Firstnam is required.</small
      >
    </div>
    <div class="p-field">
      <label for="lastname">Lastname</label>
      <InputText
        id="lastname"
        v-model.trim="user.lastName"
        required="true"
        autofocus
        :class="{ 'p-invalid': submitted && !user.lastName }"
      />
      <small class="p-error" v-if="submitted && !user.lastName"
        >Lastname is required.</small
      >
    </div>
    <div class="p-field">
      <label for="username">username</label>
      <InputText
        id="username"
        v-model.trim="user.username"
        required="true"
        autofocus
        :class="{ 'p-invalid': submitted && !user.username }"
      />
      <small class="p-error" v-if="submitted && !user.username"
        >Username is required.</small
      >
    </div>
    <div class="p-field" v-if="isnew">
      <label for="password">password</label>
      <InputText
        id="password"
        v-model.trim="user.password"
        required="true"
        autofocus
        :class="{ 'p-invalid': submitted && !user.password }"
      />
      <small class="p-error" v-if="submitted && !user.password"
        >Password is required.</small
      >
    </div>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="hideDialog"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        @click="saveUser"
      />
    </template>
  </Dialog>

  <Dialog
    v-model:visible="deleteUserDialog"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="user"
        >Are you sure you want to delete <b>{{ user.username }}</b
        >?</span
      >
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteUserDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteUser"
      />
    </template>
  </Dialog>
</template>

<script>
import userService from "../../services/user_service"

export default {
  data() {
    return {
      users: null,
      userDialog: false,
      deleteUserDialog: false,
      user: {},
      submitted: false,
      isnew: false,
    }
  },
  userservice: null,
  created() {
    this.userservice = new userService()
  },
  mounted() {
    this.userservice.getAll().then((data) => (this.users = data))
  },
  methods: {
    openNew() {
      this.user = {}
      this.submitted = false
      this.userDialog = true
      this.isnew = true
    },
    hideDialog() {
      this.userDialog = false
      this.submitted = false
    },
    saveUser() {
      this.submitted = true

      if (this.user.username.trim()) {
        if (this.user.id) {
          this.userservice
            .update(this.user)
            .then(() => {
              this.userservice.getAll().then((data) => (this.users = data))
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "User Updated",
                life: 3000,
              })
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              })
            })
        } else {
          this.userservice
            .register(this.user)
            .then(() => {
              this.userservice.getAll().then((data) => (this.users = data))
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "User Created",
                life: 3000,
              })
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              })
            })
        }
        this.userDialog = false
        this.user = {}
      }
    },
    editUser(user) {
      this.isnew = false
      this.user = { ...user }
      this.userDialog = true
    },
    confirmDeleteUser(user) {
      this.user = user
      this.deleteUserDialog = true
    },
    deleteUser() {
      this.userservice
        ._delete(this.user.id)
        .then(() => {
          this.userservice.getAll().then((data) => (this.users = data))
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "User Created",
            life: 3000,
          })
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err,
            life: 3000,
          })
        })
      this.deleteUserDialog = false
      this.user = {}
    },
  },
}
</script>
